import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { submitQuoteHandler } from 'store/redux/actions';
import { TErrorResponse } from 'types/utils';

import { IApplicationState } from './types';

export const initialState: IApplicationState = {
    brazeId: '',
    error: undefined,
    featureFlags: null,
    instantQuote: {
        applicationID: '',
        approvedAmount: '',
        branchInformation: {
            BranchId: '',
            BranchManagerName: '',
            BranchName: '',
            ContactInfo: {
                Phone: [{ PhoneNumber: '' }],
                PostalAddressData: [
                    {
                        Address1: '',
                        City: '',
                        State: '',
                    },
                ],
            },
        },
        primaryCustType: '',
        product: {
            APR: 0,
            disabilityInsuranceAmt: null,
            IUIInsuranceAmt: null,
            lifeInsuranceAmt: null,
            monthlyPaymentAmtWithInsurance: 0,
            paymentAmtWithoutInsurance: 0,
            qualifiedAmount: 0,
            quote: 0,
            rate: 0,
            requestedLoanAmt: 0,
            term: 0,
        },
        responseStatus: {
            statusCode: 0,
            statusMessages: [''],
            statusTimeStamp: 0,
        },
        result: null,
    },
    IQ_AUTH: false,
    loading: false,
    loanStartTime: 0,
};

export const applicationSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(submitQuoteHandler.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitQuoteHandler.fulfilled, (state, { payload }) => {
                state.loading = false;
                localStorage.setItem('token', payload.applicationID);
                state.instantQuote = payload;
                state.error = undefined;
            })
            .addCase(submitQuoteHandler.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as TErrorResponse;
            });
    },
    initialState,
    name: 'application',
    reducers: {
        resetApplication(state) {
            state.IQ_AUTH = initialState.IQ_AUTH;
        },
        setbrazeId(state, action: PayloadAction<IApplicationState['brazeId']>) {
            state.brazeId = action.payload;
        },
        updateFeatureFlags(state, action: PayloadAction<Record<string, string>>) {
            state.featureFlags = action.payload;
        },
        updateIqAuth(state, action: PayloadAction<IApplicationState['IQ_AUTH']>) {
            state.IQ_AUTH = action.payload;
        },
        updateLoanStartTime(state, action: PayloadAction<IApplicationState['loanStartTime']>) {
            state.loanStartTime = action.payload;
        },
    },
});
