import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { icons } from '@fairstone/ui/core';
import { Card } from '@fairstone/ui/core/components/Card';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/providers/Intl/locales/translate';

import styles from './CommonTips.module.scss';

interface ICommonTipsProps {
    scenario?: 'DECLINED' | 'ERROR' | 'FIND_BRANCH' | 'TRUSTPILOT';
}

const CommonTips: React.FC<ICommonTipsProps> = ({ scenario }) => {
    const { locale } = useIntl();

    const ref = useRef(null);
    useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <Card className={styles.commonTips}>
            {scenario === 'DECLINED' && (
                <>
                    <Typography variant="h3">{t('pages.instantQuote.instantQuoteRejectedTextP1')}</Typography>
                    <Typography component="p" variant="body3">
                        {t('pages.instantQuote.instantQuoteRejectedTextP2')}
                    </Typography>{' '}
                    <Link
                        className={styles.link}
                        href={translate('pages.instantQuote.commons.homeUrl')}
                        target="_blank"
                    >
                        {t('pages.instantQuote.commons.checkItOut')}
                    </Link>
                </>
            )}

            {scenario === 'ERROR' && (
                <>
                    <Typography variant="h3">{t('pages.instantQuote.commons.inTheMeantime')}</Typography>
                    <Typography component="p" variant="body3">
                        {t('error.errorPageHint')}
                    </Typography>

                    <Link className={styles.link} href={translate('global.link.fairstoneBlog')} target="_blank">
                        {t('pages.instantQuote.commons.checkItOut')}
                    </Link>
                </>
            )}

            {scenario === 'FIND_BRANCH' && (
                <>
                    <Typography variant="h3">{t('pages.instantQuote.instantQuoteNextStepTitle')}</Typography>
                    <Typography component="p" variant="body3">
                        {t('pages.instantQuote.instantQuoteNextStepDesc')}
                    </Typography>{' '}
                    <Link
                        className={styles.link}
                        href={translate('pages.instantQuote.commons.branchLocationsUrl')}
                        target="_blank"
                    >
                        {t('pages.instantQuote.commons.findYourBranch')}
                        <icons.NavigateNext className={styles.nextIcon} />
                    </Link>
                </>
            )}

            {scenario === 'TRUSTPILOT' && (
                <>
                    <Typography variant="h3">{t('pages.instantQuote.commons.inTheMeantime')}</Typography>
                    <Typography component="p" variant="body3">
                        {t('pages.instantQuote.commons.trustpilotFeedback')}
                    </Typography>
                    {/* TrustBox widget - Review Collector */}
                    <div
                        className="trustpilot-widget"
                        data-businessunit-id="5c3473a2aa37240001eb8d54"
                        data-locale={locale === 'en-CA' ? locale : 'fr-FR'}
                        data-style-height="52px"
                        data-style-width="100%"
                        data-template-id="56278e9abfbbba0bdcd568bc"
                        ref={ref}
                    >
                        <a href={translate('pages.instantQuote.commons.trustpilotUrl')} rel="noopener" target="_blank">
                            {t('pages.instantQuote.commons.trustpilot')}
                        </a>
                    </div>
                </>
            )}

            {!scenario && (
                <>
                    <Typography variant="h3">{t('pages.instantQuote.commons.inTheMeantime')}</Typography>
                    <Typography component="p" variant="body3">
                        {t('pages.instantQuote.commons.referUs', {
                            a: (chunks: string) => (
                                <Link href={translate('pages.instantQuote.commons.referUsUrl')} target="_blank">
                                    {chunks}
                                </Link>
                            ),
                        })}
                    </Typography>
                    <Link
                        className={styles.link}
                        href={translate('pages.instantQuote.commons.homeUrl')}
                        target="_blank"
                    >
                        {t('pages.instantQuote.commons.checkItOut')}
                        <icons.NavigateNext className={styles.nextIcon} />
                    </Link>
                </>
            )}
        </Card>
    );
};

export default CommonTips;
