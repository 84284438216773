import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { submitQuote } from 'services/api';
import { buildQuote } from 'services/quote';
import { IQuotePayload } from 'store/redux/modules/application/types';
import { RootState, TResetEventPayload, TSubmitQuotePayload } from 'types/store';
import { EErrorTypes } from 'types/utils';

export const submitQuoteHandler = createAsyncThunk(
    'application/submitQuote',
    async ({ brazeId, language }: TSubmitQuotePayload, { getState, rejectWithValue }) => {
        try {
            const quote: IQuotePayload = buildQuote(language, brazeId, getState() as RootState);
            const response = await submitQuote(quote);
            return response.data.quote;
        } catch (error: any) {
            const errorResponse = error.response?.data;
            const formattedError = {
                message: errorResponse?.message || 'An error occurred',
                type: errorResponse?.type || EErrorTypes.SERVER_ERROR,
            };
            TrackJS.track(formattedError.message);
            return rejectWithValue(formattedError);
        }
    }
);

export const ON_RESET_EVENT = 'application/reset/event';
export const onResetEvent = createAction<TResetEventPayload>(ON_RESET_EVENT);
