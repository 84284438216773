import { useEffect } from 'react';
import { Box, Typography } from '@fairstone/ui/core';
import { icons } from '@fairstone/ui/core';
import { Card } from '@fairstone/ui/core/components/Card';
import { t } from '@fairstone/ui/core/utils/translate';

import CommonTips from 'components/CommonTips';

import styles from './CreditBureauError.module.scss';

export const CreditBureauError: React.FC = () => {
    useEffect(() => {
        history.pushState(null, '', location.href);
        window.onpopstate = function () {
            history.go(1);
        };
    }, []);

    return (
        <Box className={styles.container}>
            <Card className={styles.screen}>
                <icons.InfoOutlined className={styles.icon} />
                <Typography className={styles.header} variant="h1">
                    {t('error.creditBureauErrorPageTitle')}
                </Typography>
                <Typography className={styles.text} component="p" variant="body3">
                    {t('error.creditBureauErrorPageText', {
                        br: <br></br>,
                    })}
                </Typography>
                <CommonTips scenario="FIND_BRANCH" />
            </Card>
        </Box>
    );
};
