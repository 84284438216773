export type TSelectOption = {
    label: string;
    value: number;
};

export type TEventBridgePayload = { brazeId: string } & Record<string, unknown>;

export type TErrorResponse = {
    message: string;
    type: string;
};

export enum EErrorTypes {
    CREDIT_BUREAU_ERROR = 'CREDIT_BUREAU_ERROR',
    SERVER_ERROR = 'SERVER_ERROR',
}
